import React, { useEffect, useState } from "react";
import { CircularProgress, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment/moment";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { get_pilot_list_api, update_pilot_verification_api } from "../../DAL/Pilots/Pilots";
import * as XLSX from "xlsx";
import { Switch } from "@mui/material";

export default function PilotsList() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [pilotsList, setPilotsList] = useState([]);

  const get_pilots_list = async () => {
    try {
      const result = await get_pilot_list_api();
      if (result.code === 200) {
        const data = result.pilot_list.map((pilot) => ({
          ...pilot,
          name: pilot.first_name + " " + pilot.last_name,
          certificate_status: pilot.certificate_image ? (
            <a href={pilot.certificate_image} target="_blank" rel="noopener noreferrer">Yes</a>
          ) : "No",
          night_rating_status: pilot.night_rating_file ? (
            <a href={pilot.night_rating_file} target="_blank" rel="noopener noreferrer">Yes</a>
          ) : "No",
          instrument_rating_status: pilot.instrument_rating_file ? (
            <a href={pilot.instrument_rating_file} target="_blank" rel="noopener noreferrer">Yes</a>
          ) : "No",
          birth_date: moment(pilot.date_of_birth).format("DD-MM-YYYY") + ` (${moment().diff(pilot.date_of_birth, 'years')})`,
          favorite_airport: pilot.favorite_airport && pilot.favorite_airport.title? pilot.favorite_airport.title : 'N/A', // Map airport ID to title
          paymentPlan: pilot.paymentPlan,
          verified: pilot.verified ? "Yes" : "No",
        }));
        setPilotsList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching pilots list:", error);
      enqueueSnackbar("Error fetching pilots list", { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleVerificationToggle = async (pilotId, isVerified) => {
    // Update pilot verification status in the state
    console.log("alhjwiegf", pilotId, isVerified);
    const updatedPilotsList = pilotsList.map((pilot) =>
      pilot._id === pilotId ? { ...pilot, verified: isVerified ? "Yes" : "No" } : pilot
    );
    console.log('updatedPilotsList', updatedPilotsList)
    setPilotsList(updatedPilotsList);

    // Convert frontend string representation to backend boolean
    const backendVerificationStatus = isVerified ? true : false;

    try {
      const result = await update_pilot_verification_api(pilotId, backendVerificationStatus);
      if (result.code === 200) {
        enqueueSnackbar("Pilot verification status updated successfully", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        // Revert pilot verification status in the state if update fails
        const revertedPilotsList = pilotsList.map((pilot) =>
          pilot.id === pilotId ? { ...pilot, verified: isVerified ? "No" : "Yes" } : pilot
        );
        setPilotsList(revertedPilotsList);
      }
    } catch (error) {
      console.error("Error updating pilot verification status:", error);
      enqueueSnackbar("Error updating pilot verification status", { variant: "error" });
      // Revert pilot verification status in the state if update fails
      const revertedPilotsList = pilotsList.map((pilot) =>
        pilot.id === pilotId ? { ...pilot, verified: isVerified ? "No" : "Yes" } : pilot
      );
      setPilotsList(revertedPilotsList);
    }
  };

  const exportToExcel = () => {
    const fileName = "pilots_list.xlsx";
    const worksheet = XLSX.utils.json_to_sheet(pilotsList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Pilots");
    XLSX.writeFile(workbook, fileName);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone_number", label: "Phone" },
    { id: "birth_date", label: "Birth Date" },
    { id: "certificate_status", label: "PL" }, // Display pilot license status
    { id: "night_rating_status", label: "NR" }, // Display night rating status
    { id: "instrument_rating_status", label: "IR" }, // Display instrument rating status
    { id: "total_hours", label: "TH" },
    { id: "favorite_airport", label: "Base", },
    { id: "paymentPlan", label: "Payment Plan" },
    { id: "status", label: "Status", type: "row_status" },
    // { id: "verified", label: "Verified", type: "toggle", onToggle: handleVerificationToggle, options: ["No", "Yes"] },
    {
      id: "verified",
      label: "Verified",
      renderData: (row) => (
        <Switch
          checked={row.verified === "Yes"}
          onChange={() => handleVerificationToggle(row._id, row.verified !== "Yes")}
          color="primary"
        />
      ),
    },
  ];

  useEffect(() => {
    get_pilots_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="circular-progress">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className="container mt-3">
          <div className="row">
            <div className="col-12">
              <div className="theme-card p-3">
                <div className="d-flex justify-content-between light-border-bottom">
                  <div className="mb-2 mui-table-heading">
                    <h2>Pilots List</h2>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={exportToExcel}
                    >
                      Export to Excel
                    </Button>
                  </div>
                </div>
                <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={pilotsList} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
