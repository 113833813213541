import { invokeApi } from "../../bl_libs/invokeApi";

export const get_flights_list_api = async () => {
  const requestObj = {
    path: `api/flight/list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_flight_api = async (data) => {
  const requestObj = {
    path: `api/flight/add`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_flight_api = async (airport_id) => {
  const requestObj = {
    path: `api/flight/delete/${airport_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};