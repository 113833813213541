import { invokeApi } from "../../bl_libs/invokeApi";

// Function to fetch pilot list
export const get_pilot_list_api = async () => {
  const requestObj = {
    path: `api/pilot/get_pilot_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return await invokeApi(requestObj);
};

// Function to update pilot verification status
export const update_pilot_verification_api = async (
  pilotId,
  verifiedStatus
) => {
  const requestObj = {
    path: `api/pilot/update_verification/${pilotId}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ verified: verifiedStatus }),
  };
  return await invokeApi(requestObj);
};
