import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { delete_flight_api, get_flights_list_api } from "../../DAL/Flight/Flight";

function FlightsList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [flightsList, setFlightsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "_id", label: "ID", alignRight: false },
    { id: "departure_airport", label: "Departure Airport", alignRight: false },
    { id: "return_airport", label: "Arrival Airport", alignRight: false },
    { id: "departure_date", label: "Scheduled Departure Date", alignRight: false, type: "date" },
    { id: "departuretime", label: "Scheduled Departure Time", alignRight: false },
    { id: "pilot", label: "Pilot's Name", alignRight: false },
    { id: "actype", label: "Aircraft Type", alignRight: false },
    { id: "totalCost", label: "Total Cost", alignRight: false, type: "number" },
    { id: "status", label: "Status", type: "row_status" },
  ];

  const handleEdit = (value) => {
    navigate(`/flights-list/edit-flight/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_flight_api(selectedObject._id);
    if (result.code === 200) {
      const updatedFlightsList = flightsList.filter(
        (flight) => flight._id !== selectedObject._id
      );
      setFlightsList(updatedFlightsList);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const fetchFlightsList = async () => {
      try {
        const result = await get_flights_list_api();
        if (result.code === 200) {
          if (result.flight_list.length === 0) {
            setErrorMessage("No flights available.");
          } else {
            const formattedFlights = result.flight_list.map((flight) => ({
              ...flight,
              address: `${flight.address_1} ${flight.city_name}`,
            }));
            setFlightsList(formattedFlights);
          }
        } else {
          setErrorMessage(result.message || "Failed to fetch flights.");
        }
      } catch (error) {
        setErrorMessage("Error fetching flights list.");
      }
      setIsLoading(false);
    };

    fetchFlightsList();
  }, [enqueueSnackbar]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Flights List</h2>
                </div>
              </div>
              {errorMessage ? (
                <Typography color="error">{errorMessage}</Typography>
              ) : (
                <CustomMUITable
                  TABLE_HEAD={TABLE_HEAD}
                  data={flightsList}
                  MENU_OPTIONS={MENU_OPTIONS}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title="Are you sure you want to delete this flight?"
        handleAgree={handleDelete}
      />
    </>
  );
}

export default FlightsList;
