import React, { useEffect, useState } from "react";
import { CircularProgress, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment/moment";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../config/config";
import { get_member_list_api } from "../../DAL/Passenger/Passenger";
import { get_airport_list_api } from "../../DAL/AirPorts/AirPorts";
import * as XLSX from "xlsx";

export default function PassengersList() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [passengersList, setPassengersList] = useState([]);
  const [airports, setAirports] = useState([]);

  useEffect(() => {
    getPassengerList();
    fetchAirports();
  }, []);

  const fetchAirports = async () => {
    try {
      const result = await get_airport_list_api();
      if (result.code === 200) {
        setAirports(result.airports);
      } else {
        enqueueSnackbar("Error fetching airports list", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching airports list:", error);
      enqueueSnackbar("Error fetching airports list", { variant: "error" });
    }
  };

  const getPassengerList = async () => {
    try {
      const result = await get_member_list_api();
      if (result.code === 200) {
        console.log(result)
        const data = result.member_list.map((member) => {

          return {
            ...member,
            name: member.first_name + " " + member.last_name,
            table_avatar: {
              src: baseUrl + member.profile_image,
              alt: member.name,
            },
            birth_date:
              moment(member.date_of_birth).format("DD-MM-YYYY") +
              ` (${moment().diff(member.date_of_birth, "years")})`,
            favorite_airport: member.favorite_airport && member.favorite_airport.title? member.favorite_airport.title : 'N/A', // Map airport ID to title
            paymentPlan: member.paymentPlan,
          };
        });
        setPassengersList(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching passenger list:", error);
      enqueueSnackbar("Error fetching passenger list", { variant: "error" });
      setIsLoading(false);
    }
  };

  const exportToExcel = () => {
    const fileName = "passengers_list.xlsx";
    const worksheet = XLSX.utils.json_to_sheet(passengersList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Passengers");
    XLSX.writeFile(workbook, fileName);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "phone_number", label: "Phone", alignRight: false },
    { id: "birth_date", label: "Birth Date", alignRight: false },
    { id: "favorite_airport", label: "Favorite Airport", alignRight: false },
    { id: "paymentPlan", label: "Payment Plan", alignRight: false },
    { id: "status", label: "Status", type: "row_status" },
  ];

  return (
    <>
      {isLoading ? (
        <div className="circular-progress">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className="container mt-3">
          <div className="row">
            <div className="col-12">
              <div className="theme-card p-3">
                <div className="d-flex justify-content-between light-border-bottom">
                  <div className="mb-2 mui-table-heading">
                    <h2>Passengers List</h2>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={exportToExcel}
                    >
                      Export to Excel
                    </Button>
                  </div>
                </div>
                <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={passengersList} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
