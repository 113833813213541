import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidIcon from '@mui/icons-material/Paid';
import HikingIcon from '@mui/icons-material/Hiking';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const get_icon = (name) => {
  // Mapping icon names to MUI icons
  const icons = {
    dashboard: <DashboardIcon />,
    passengers: <HikingIcon/>,
    pilots: <PaidIcon />,
    airports: <AirportShuttleIcon />,
    flights: <FlightTakeoffIcon />,
    // Add more mappings as needed
  };

  return icons[name] || null; // Return the icon or null if not found
};

const sidebarConfig = () => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("dashboard"),
    },
    {
      title: "Passengers List",
      path: "/passengers-list",
      icon: get_icon("passengers"),
    },
    {
      title: "Pilots List",
      path: "/pilots-list",
      icon: get_icon("pilots"),
    },
    {
      title: "Airports List",
      path: "/airports-list",
      icon: get_icon("airports"),
    },
    {
      title: "Flights List",
      path: "/flights-list",
      icon: get_icon("flights"),
    },
    // Uncomment and add more menu items as needed
    // {
    //   title: "Payment Plans",
    //   path: "/payment-plans",
    //   icon: get_icon("payment-plans"),
    // },
    // {
    //   title: "Home Page Content",
    //   path: "/home-page-content",
    //   icon: get_icon("home-page-content"),
    // },
    // {
    //   title: "Settings",
    //   icon: get_icon("settings"),
    //   child_options: [
    //     {
    //       title: "Home Page Content",
    //       path: "/home-page-content",
    //       icon: get_icon("home-page-content"),
    //     },
    //   ],
    // },
  ];

  return sidebarMenus;
};

export default sidebarConfig;
